@font-face {
  font-family: "Regular";   /*Can be any text*/
  src: local("Regular"),
      url("./assets/font/NeurialGrotesk-Regular.otf") format("opentype");
}
@font-face {
  font-family: "Light";   /*Can be any text*/
  src: local("Light"),
      url("./assets/font/NeurialGrotesk-Light.otf") format("opentype");
}

div {
  box-sizing: border-box;
}

@keyframes slideInFromBottom {
  0% {
      transform: translateY(110%) skewX(-20deg);
  }
  100% {
      transform: translateY(0);
  }
}

@keyframes fade-in {
  0% {
      opacity: 0;
  }
  100% {
      opacity: 1;
  }
}

@keyframes scrollAnimation {
    0% {
        transform: translateX(0);
    }
    100% {
        transform: translateX(calc(-100% - 20px));
    }
}